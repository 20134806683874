import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/layout/home'
  },
  {
    path: '/layout',
    component: () => import('@/views/layout.vue'),
    children: [
      {
        path: 'home',
        component: () => import('@/views/home.vue')
      },
      {
        path: 'about',
        component: () => import('@/views/about.vue')
      },
      {
        path: 'new',
        component: () => import('@/views/new.vue')
      },
      {
        path: 'newDetail/:id',
        component: () => import('@/views/newDetail.vue')
      },
      {
        path: 'search',
        component: () => import('@/views/search.vue')
      },
      {
        path: 'case',
        component: () => import('@/views/case.vue')
      },
      {
        path: 'moreCase',
        component: () => import('@/views/moreCase.vue')
      },
      {
        path: 'building',
        component: () => import('@/views/building.vue')
      },
      {
        path: 'work',
        component: () => import('@/views/work.vue')
      },
      {
        path: 'technology',
        component: () => import('@/views/technology.vue')
      },
    ]
  },
]

const router = new VueRouter({
  // mode: "history",
  routes,
  scrollBehavior: () => ({ y: 0 }),
})

export default router
