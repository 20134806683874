<template>
  <div id="app">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import autofit from "@/utils/autofit";

export default {
  data() {
    return {
      setHeight: 850,
    };
  },
  mounted() {
    // autofit.init(
    //   {
    //     designHeight: 1040,
    //     designWidth: 1920,
    //     renderDom: "#app",
    //     resize: true,
    //   },
    //   false
    // ); // 可关闭控制台运行提示输出

    let clientHeight = document.documentElement.clientHeight;
    if (clientHeight > 900) {
      this.setHeight = clientHeight - 72;
    }
    document
      .getElementsByTagName("body")[0]
      .style.setProperty("--imgHeight", this.setHeight + "px");
  },
};
</script>

<style lang="scss">
$imgHeight: var(--imgHeight); //用var来盛放变量名
body {
  font-size: 16px;
  color: #333333;
  background-color: #f5f8fc;
}
.banner-bg-area {
  min-width: 1400px;
  height: $imgHeight;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 1900px) {
  .banner-bg-area {
    // background-size: auto 100%;
  }
}
@media screen and (min-width: 2500px) {
  .banner-bg-area {
    background-size: 100% auto;
  }
}
.limit-width {
  width: 1400px;
  margin: 0 auto;
}
.min-width {
  min-width: 1400px;
  margin: 0 auto;
}

@media screen and (max-width: 1405px) {
  .limit-width {
    // width: 100%;
    padding-left: 20px !important;
    padding-right: 20px !important;
    box-sizing: border-box;
  }
}

.block-image {
  display: block;
  width: 100%;
  min-width: 1400px;
  height: $imgHeight;
  object-fit: cover;
}
.f {
  display: flex;
}
.f-row-s-c {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.f-row-c-c {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.f-row-b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.f-row-b-c {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.i-f-row-c-c {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.i-f-row-b {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
/*
  滚动条样式修改
*/
.scrollbar-area::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.scrollbar-area::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  background: #ccc;
}
</style>
